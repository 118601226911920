import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import NeoModal from "src/NeoModal";

export default class extends Controller {
  static targets = ['table'];

  addImage(e) {
    e.preventDefault();

    const button = e.target;
    const { url, table } = button.dataset;

    axios.get(url).then((response) => {
      const modal = NeoModal.add(response.data, {});

      modal.addEventListener('neo_modal:success', () => {
        $(modal).modal('hide');

        if (table) Neo.reloadTable(table);
      });
    });
  }

  removeImage(e) {
    e.preventDefault();

    const target = e.target;
    const form = target.closest('form');
    const { url, table } = target.dataset

    axios.delete(url).then((response) => {
      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(response.data, 'text/html');
      const parsedForm = parsedHTML.querySelector('form');
      const tableEl = document.getElementById(table);

      if (tableEl) Neo.reloadTable(table);

      form.innerHTML = parsedForm.getInnerHTML();
    });
  }
}
