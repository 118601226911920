import { Controller } from "@hotwired/stimulus";
import axios from 'axios';

class CustomMessageSignatureController extends Controller {
  static targets = ['body', 'enabledSignature'];

  connect() {
    this.signatureLinks = {
      conversation: {
        showModal: '/admin/custom_message_signature',
      },
      membersConversation: {
        showModal: '/admin/online_membership_message_signature',
      }
    }
  }

  showModal(e) {
    e.preventDefault();

    const subMenuElement = document.querySelector('.maintabs_submenu');
    const target = e.target;
    const modal = document.getElementById('modal_form');
    const content = modal.querySelector('.modal-content');
    const venueId = subMenuElement.dataset.venue_id;
    const url = `${this.currentSignatureLinks().showModal}?venue_id=${venueId}`;

    axios.get(url).then((response) => {
      content.innerHTML = response.data;
    });

    $(modal).modal('show');
  }

  selectVenue(e) {
    const target = e.target;
    const url = `${this.currentSignatureLinks().showModal}.json?venue_id=${target.value}`;
    const body = this.bodyTarget;
    const enabledSignatureCheckbox = this.enabledSignatureTarget;

    if (target.value === 'all-venues') {
      body.value = '';
      enabledSignatureCheckbox.checked = false

      return null;
    }

    axios.get(url).then((response) => {
      body.value = response.data.body;
      enabledSignatureCheckbox.checked = response.data.enabled;
    });
  }

  currentSignatureLinks() {
    if (this.isMembersConversationPage()) {
      return this.signatureLinks.membersConversation;
    } else {
      return this.signatureLinks.conversation;
    }
  }

  isMembersConversationPage() {
    return window.location.pathname === '/admin/members_conversations'
  }
}

export default CustomMessageSignatureController;
