import OrdersDataTable from './ordersDataTable';

class OrdersTable {
  start() {
    $('#orders-table').on('reloadData', () => this.reloadData());
    this.toDataTable();
    this.prepareDataTableColumns();
    $('#orders-table').on('draw.dt', this.onReDraw);
  }

  init() {}

  toDataTable() {
    const failedPaymentFilter = document.getElementById('filter_modification_status_failed_payment');
    const failedPaymentFilterChecked =  failedPaymentFilter && failedPaymentFilter.checked
    const adminFirstColumnSortDir = (this.isCheckedStatus(['pending', 'pending_user']) || failedPaymentFilterChecked) ? 'asc' : 'desc'
    const accountColumnSortDir = failedPaymentFilterChecked ? 'asc' : 'desc'

    this.commonDataTable($('#orders-table.account_orders_table'), accountColumnSortDir, [2, 4, 5, 6, 7, 8]);
    this.commonDataTable($('#orders-table.account_bookings_table'), 'asc', [3, 4, 5]);
    this.commonDataTable($('#orders-table.admin_bookings_table'), 'asc', [2, 3, 4]);
    this.commonDataTable($('#orders-table.admin_offline_orders_table'), 'asc', [2, 3, 4, 5, 6, 7, 8]);
    this.commonDataTable($('#orders-table.account_member_orders_table'), 'asc', [3 ,4, 5, 6, 7, 8]);

    const defaultParams = (new OrdersDataTable).defaultParams(this.ordersURL);

    $('#orders-table.admin_orders_table').dataTable({
      ...defaultParams,
      order: [[0, adminFirstColumnSortDir]],
      columnDefs: [
        {
          targets: [3, 4, 5, 6, 7, 8],
          orderable: false
        },
        {
          targets: [4, 5, 6],
          width: '10%'
        }
      ]
    });
  }



  commonDataTable($element, direction, targets) {
    const defaultParams = (new OrdersDataTable).defaultParams(this.ordersURL);

    $element.dataTable({
      ...defaultParams,
      order: [[0, direction]],
      columnDefs: [
        {
          targets: targets,
          orderable: false
        }
      ],
      language: {
        emptyTable: 'There is no data available. Please change your filters and try again.'
      }
    })
  }

  onReDraw() {
    const accountBookingsTable = $('#orders-table.account_bookings_table').length > 0;
    const accountOrdersTable = $('#orders-table.account_orders_table').length > 0;
    const adminBookingsTable = $('#orders-table.admin_bookings_table').length > 0;
    const adminMemberOrdersTable = $('#orders-table.admin_member_orders_table').length > 0;

    let venueColumnIndex = 2;

    if (accountBookingsTable || adminBookingsTable || accountOrdersTable) {
      venueColumnIndex = 1;
    } else if (adminMemberOrdersTable) {
      venueColumnIndex = 3;
    }

    if (!(accountOrdersTable || adminBookingsTable)) {
      const isVisible = $('#filter_venue_id').val() === '';
      const hasMoreThanTwoOptions = $('#filter_venue_id').find('option').length > 2;

      $('#orders-table').DataTable().column(venueColumnIndex).visible(isVisible) && hasMoreThanTwoOptions;
    }
  }

  reloadData(changePage) {
    const ajax = $('#orders-table').DataTable().ajax;
          ajax.url(this.ordersURL);
          ajax.reload(null, changePage);
  };

  get ordersURL() {
    const emailURL = new RegExp('[\?&]email=([^&#]*)').exec(window.location.href);
    let emailParameter;

    if (emailURL) {
      emailParameter = '&filter%5Bemail=' + emailURL[1];
    }

    let url = $('#orders-table').data('source') + '?' + $('form#new_filter').serialize();

    if (emailParameter) {
      url = url + emailParameter;
    }

    return url;
  }

  prepareDataTableColumns() {
    const identifiers = ['admin', 'account'];
    const columnIndexes = [0];

    identifiers.map((identifier) => {
      const table = $(`table.${identifier}_orders_table`);

      if (table.length === 0) return;

      const dataTable = table.DataTable();

      columnIndexes.map((index) => {
        this.onChangeFilterUpdateColumnTitle(identifier, dataTable, index)
      });
    });
  }

  onChangeFilterUpdateColumnTitle(identifier, dataTable, columnIndex) {
    const dataColumn = dataTable.column(columnIndex).header();
    const [dataOrderDir] = dataTable.order()[columnIndex] || [null];
    const failedPaymentFilter = document.getElementById('filter_modification_status_failed_payment');
    const sentRequestFilter = document.getElementById('filter_modification_status_sent_request');

    if (columnIndex === 0) {
      if (identifier === 'admin' && this.isCheckedStatus(['pending', 'pending_user'])) {
        dataColumn.innerText = 'Date of first session';
        if (dataOrderDir !== 'asc') dataTable.order([columnIndex, 'asc']);
      } else if (failedPaymentFilter.checked) {
        dataColumn.innerText = 'Date of failed payment';
        if (dataOrderDir !== 'asc') dataTable.order([columnIndex, 'asc']);
      } else if (sentRequestFilter && sentRequestFilter.checked) {
        dataColumn.innerText = 'Date of amendment request';
      } else if (this.isCheckedStatus(['cancellation_requested'])) {
        dataColumn.innerText = 'Date of cancellation request';
      } else {
        dataColumn.innerText = 'Date of request';
        if (dataOrderDir !== 'desc') dataTable.order([columnIndex, 'desc']);
      }
    }
  }

  isCheckedStatus(values) {
    const checkedCheckboxes = $('#new_filter .filter_statuses input[type=checkbox]:checked');
    const statuses = Array.from(checkedCheckboxes, status => status.value);
    const excludedStatuses = new Set(statuses.filter(x => !values.includes(x)));

    return excludedStatuses.size === 0 && statuses.length > 0;
  }
}

export default OrdersTable;
